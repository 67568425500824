<template>
    <div class="registration">
        <div class="registration__heading">
            <h1>Вiдновлення пароля</h1>
        </div>
        <div class="registration__content">
            <form action id="pills-home" @submit.prevent="submitHandler()" autocomplete="off">
                <div v-if="forgotSuccess">
                    <div class="alert alert-success" role="alert">
                        <p class="text-center">
                            На вашу пошту відправлено лист з подальшою інструкцією для відновлення пароля.
                        </p>
                    </div>
                </div>
                <div v-else>
                    <div class="alert alert-primary" role="alert">
                        <p>
                            Щоб відновити пароль, введіть адресу електронної пошти, за допомогою якої Ви входите в систему.
                        </p>
                        <p>
                            На Вашу електронну адресу буде відправлено листа із зазначенням подальших дій
                        </p>
                    </div>
                    <div class="registration__form-group">
                        <input :class="{ 'is-invalid': hasError('email') }" :disabled="isLoad" v-model.trim="email" placeholder="Email" autocomplete="off"/>
                        <div v-if="hasError('email')" class="invalid-feedback">
                            {{ getError('email') }}
                        </div>
                        <button :disabled="isLoad" type="submit" class="submit">
                            <span v-if="isLoad" style="margin-bottom: 4px;" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Вiдновити
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { sendResetLinkEmail } from '@/api/auth'

export default {
    name: 'resetPassword',
    data: () => ({
        errors: null,
        isLoad: false,
        forgotSuccess: false,
        email: null
    }),
    methods: {
        getError(key) {
            return this.errors[key][0]; //return first error
        },
        hasError(key) {
            return _.has(this.errors, key);
        },
         submitHandler:async function () {
             this.errors = null;
             this.isLoad = true;

            await sendResetLinkEmail(this.email).then((response) => {
                this.forgotSuccess = true;
                this.isLoad = false;
            }).catch((error) => {
                this.isLoad = false;
                this.errors = error.response.data.data;
                return false;
            });
        },
    }
}
</script>

